<template>
  <el-form ref="form" :inline="true"  :model="form" label-width="120px">
    <el-input v-model="form.ins_id" type="hidden"></el-input>
    <div>
      <p>产品信息</p>
      <el-form-item label="产品型号">
        <el-input v-model="form.pro_model"></el-input>
      </el-form-item>
      <el-form-item label="产品批号">
        <el-input v-model="form.pro_batch"></el-input>
      </el-form-item>
      <el-form-item label="产品炉号">
        <el-input v-model="form.pro_heat"></el-input>
      </el-form-item>
      <el-form-item label="产品牌号">
        <el-input v-model="form.pro_grade"></el-input>
      </el-form-item>
<!--      <el-form-item label="产品材质">-->
<!--        <el-input v-model="form.pro_steel"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="产品外径">
        <el-input v-model="form.pro_od"></el-input>
      </el-form-item>
      <el-form-item label="产品壁厚">
        <el-input v-model="form.pro_thick"></el-input>
      </el-form-item>
      <el-form-item label="产品长度">
        <el-input v-model="form.pro_length"></el-input>
      </el-form-item>
      <el-form-item label="弯曲半径">
        <el-input v-model="form.pro_radius"></el-input>
      </el-form-item>
      <el-form-item label="产品数量">
        <el-input v-model="form.pro_piece"></el-input>
      </el-form-item>
      <el-form-item label="产品重量">
        <el-input v-model="form.pro_weight"></el-input>
      </el-form-item>
      <el-form-item label="产品状态">
        <el-input v-model="form.pro_status"></el-input>
      </el-form-item>

    </div>

    <div><p>化学分析</p>

      <el-form-item label="分析炉号">
        <el-input v-model="form.che_heat"></el-input>
      </el-form-item>
            <el-form-item label="元素">
              <el-input v-model="form.che_test"></el-input>
            </el-form-item>
      <el-form-item label="C比例">
        <el-input v-model="form.che_c"></el-input>
      </el-form-item>
      <el-form-item label="Si比例">
        <el-input v-model="form.che_si"></el-input>
      </el-form-item>
      <el-form-item label="Mn比例">
        <el-input v-model="form.che_mn"></el-input>
      </el-form-item>
      <el-form-item label="P比例">
        <el-input v-model="form.che_p"></el-input>
      </el-form-item>
      <el-form-item label="S比例">
        <el-input v-model="form.che_s"></el-input>
      </el-form-item>
      <el-form-item label="Cr比例">
        <el-input v-model="form.che_cr"></el-input>
      </el-form-item>
      <el-form-item label="Ni比例">
        <el-input v-model="form.che_ni"></el-input>
      </el-form-item>
      <el-form-item label="Mo比例">
        <el-input v-model="form.che_mo"></el-input>
      </el-form-item>
      <el-form-item label="N比例">
        <el-input v-model="form.che_n"></el-input>
      </el-form-item>
      <el-form-item label="Cu比例">
        <el-input v-model="form.che_cu"></el-input>
      </el-form-item>
      `<el-form-item label="未知变量1">
        <el-input v-model="form.no_field1"></el-input>
      </el-form-item>
      <el-form-item label="未知变量2">
        <el-input v-model="form.no_field2"></el-input>
      </el-form-item>
    </div>
    <div>

      <p>性能检验</p>
            <el-form-item label="批号">
              <el-input v-model="form.per_revfla"></el-input>
            </el-form-item>

      <el-form-item label="拉伸强度">
        <el-input v-model="form.per_tensile"></el-input>
      </el-form-item>
      <el-form-item label="屈服强度">
        <el-input v-model="form.per_yield"></el-input>
      </el-form-item>
      <el-form-item label="延伸率">
        <el-input v-model="form.per_elo"></el-input>
      </el-form-item>
      <el-form-item label="硬度">
        <el-input v-model="form.per_hardness"></el-input>
      </el-form-item>
      <el-form-item label="温度">
        <el-input v-model="form.per_temperature"></el-input>
      </el-form-item>
      <el-form-item label="冲击尺寸">
        <el-input v-model="form.per_impact"></el-input>
      </el-form-item>
      <el-form-item label="平均冲击功">
        <el-input v-model="form.per_impactsvg"></el-input>
      </el-form-item>
<!--      <el-form-item label="展平">-->
<!--        <el-input v-model="form.per_revfla"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="焊缝接头背弯">-->
<!--        <el-input v-model="form.per_revbend"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="压扁">-->
<!--        <el-input v-model="form.per_flat"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="扩口">-->
<!--        <el-input v-model="form.per_flar"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="卷边">-->
<!--        <el-input v-model="form.per_flan"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="水压试验">-->
<!--        <el-input v-model="form.per_hydro"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="水下气压试验">-->
<!--        <el-input v-model="form.per_airbar"></el-input>-->
<!--      </el-form-item>-->
    </div>
    <el-form-item >
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <!--      <el-button>取消</el-button>-->
    </el-form-item>
  </el-form>
</template>
<script>
import axios from "axios";

export default {
  name: "InsinfoaView",
  data() {
    return {
      form: {}
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase(){
      let geturl = window.location.href;
      let geturlinfo = geturl.split('?')[1];
      let getqys = new URLSearchParams('?'+geturlinfo);
      let id = getqys.get('id');
      let ins_id = getqys.get('ins_id');
      axios.get('/api/Insa/getInsinfoa.html?id='+id+'&ins_id='+ins_id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }else if(res.data.code == 1003){
          this.form.ins_id = ins_id;
        }

      });
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.logo = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSubmit() {
      this.axios({
        method:'post',
        url:'/api/Insa/setInsinfoa.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
        }
      })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-input-number {
  margin-left: 10px;
}
.thisitem{display: block}
</style>