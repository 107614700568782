<template>
  <div>
    <div> <p  class="category"></p>
      <el-button size="mini" @click="goTo(0)" class="category add">新增</el-button></div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="产品型号"
      >
        <template slot-scope="scope">
          <span style="" v-html="scope.row.pro_model"></span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="goTo(scope.row.id)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="deleteContent(scope.$index,scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
    >
    </el-pagination>

  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "InsinfoView",
  data() {
    return {
      tableData: [],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {

    this.getInsid();
  },
  methods: {
    goTo(id){
      this.$router.push('/getinsinfoa?id='+id+'&ins_id='+this.id)
    },
    getInsid(){
      let geturl = window.location.href;
      let geturlinfo = geturl.split('?')[1];
      let getqys = new URLSearchParams('?'+geturlinfo);
      let id = getqys.get('id');
      this.id = id;
      this.getCate();
    },
    getCate(page = 1){
      let id = this.id;
      axios.get('/api/Insa/insinfoa.html?id='+id+'&page='+page).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    },
    deleteContent(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Insa/deleteinsinfoa.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style scoped lang=''>
</style>